import Rellax from 'rellax';
// import Glide from '@glidejs/glide';
class App {
    constructor() {
        this.datas = [];
        this.slide_time = 3000;
        this.transition_time = 1000;
    }
    home_header() {
        const home_header = document.querySelector('.home-header');
        if (home_header) {
            display_header();
            window.addEventListener('scroll', display_header);
        }
        function display_header() {
            if (window.scrollY > 100) {
                home_header.classList.add('on');
            } else {
                home_header.classList.remove('on');
            }
        }
    }

    gnav() {
        const trigger = document.querySelector('.menu-trigger');
        const header_nav = document.querySelector('.drawer');
        trigger.addEventListener('click', (e) => {
            e.preventDefault();
            trigger.classList.toggle('active');
            header_nav.classList.toggle('active');
        }, false);
    }

    home_slide() {
        const _self = this;
        let slides = document.querySelectorAll('.tactical-slide');
        let bullets = document.querySelector('.glide__bullets');

        for (let i = 0; i < slides.length; i++) {
            let button = document.createElement('span');
            button.classList.add('glide__bullet');
            button.setAttribute('data-glide-dir', '=' + i);
            let text = document.createTextNode(('00' + i).slice(-2));
            button.appendChild(text);
            bullets.append(button);
        }
        new Glide('.glide', {
            type: 'carousel',
        }).mount();

        _self.mouseChange();
    }

    moveMask() {
        const wrapper = document.querySelector('.wrapper');
        wrapper.insertAdjacentHTML('beforebegin', '<div class="masks"></div>');
        const maskswapper = document.querySelector('.masks');

        let objArray = [
            {
                hol: -650,
                yspeed: -35,
                yoffset: -400,
                scale: 1,
                mask: 2,
            },
            {
                hol: 200,
                yspeed: -80,
                yoffset: -100,
                scale: 1,
                mask: 1,
            },
            {
                hol: -550,
                yspeed: -35,
                yoffset: 900,
                scale: 1,
                mask: 2,
            },
            {
                hol: 200,
                yspeed: -60,
                yoffset: 2600,
                scale: 1,
                mask: 1,
            },
        ];

        objArray.forEach((elm, index) => {
            let tags = `
                <div
                    class="luxy-el mask"
                    data-speed-y="${elm.yspeed}"
                    data-offset="0"
                    data-speed-x="0"
                    data-horizontal='1'
                >
                    <div><div
                        class="mask-${elm.mask}"
                        style="transform: scale(${elm.scale}) translate(${elm.hol}px, ${elm.yoffset}px);"
                    ></div></div>
                </div>
            `;

            maskswapper.insertAdjacentHTML('afterbegin', tags);
        });

        document.querySelectorAll('.mask').forEach((elm, index) => {
            elm.classList.add('on');
        });
    }

    scrollObserver() {
        // オプション指定
        const options = {
            // 要素の見えている割合が20%を超える度にコールバックを実行
            threshold: [0, 0.2, 0.4, 0.6, 0.8, 1],
        };

        // 交差した時に発生するコールバック
        const callback = (entries, observer) => {
            entries.forEach((entry) => {
                // 交差している領域の割合が20%を超えた場合
                if (entry.intersectionRatio > 0.2) {
                    // アニメーションや画像の読み込みなどの処理
                    entry.target.classList.add('on');
                }
            });
        };

        // IntersectionObserverをインスタンス化
        const observer = new IntersectionObserver(callback, options);

        // 監視する要素の配列を取得
        const observers = [...document.querySelectorAll('.js-scroll')];

        // 配列に指定した要素をIntersectionObserverで監視
        observers.forEach((el) => {
            observer.observe(el);
        });
    }

    mouseChange() {
        const container = document.querySelector('body');
        const item = document.querySelector('#mouseCursor');
        container.addEventListener('mousemove', mousemove);
        function mousemove(e) {
            item.classList.add('active');
            item.style.left = e.clientX - 21.5 + 'px';
            item.style.top = e.clientY - 18 + 'px';
        }
        let anchor1 = document.querySelectorAll('a');
        let anchor2 = document.querySelectorAll('.glide__bullet');
        let anchor3 = Object.setPrototypeOf([...anchor1, ...anchor2], NodeList.prototype);
        anchor3.forEach((elm) => {
            elm.addEventListener('mouseenter', () => {
                item.classList.add('on');
            });
            elm.addEventListener('mouseout', () => {
                item.classList.remove('on');
            });
        });
    }

    detailSlide() {
        const $slideWrap = $('.slide-wrap');
        if ($slideWrap.length > 0) {
            let $slide = $slideWrap.find('.slide-item');
            let navHtml = '<ul class="slide-navs">';
            $slide.each(function (i, elm) {
                let path = $(elm).find('img').attr('src');
                navHtml += `<li data-index="${i}" style="background-image: url(${path});"></li>`;
            });
            navHtml += `</ul>`;
            $slideWrap.after($(navHtml));
            $slideWrap.slick({
                autoplay: false,
                arrows: false,
                infinite: true,
            });
            let $slideList = $('.slide-navs');
            $slideList.find('li').on('click', function () {
                let index = $(this).attr('data-index');
                $slideWrap.slick('slickGoTo', index, false);
            });
        }
    }

    init() {
        const _self = this;
        _self.gnav();
        _self.mouseChange();
        _self.detailSlide();
        if ($('#luxy').length > 0) {
            _self.moveMask();
            luxy.init();
        }
    }
}

let app = new App();

document.addEventListener('DOMContentLoaded', () => {
    app.init();
});

window.addEventListener('load', () => {

});

{
    setTimeout(() => document.body.classList.add('render'), 60);
    const navdemos = Array.from(document.querySelectorAll('nav.demos > .demo'));
    const total = navdemos.length;
    const current = navdemos.findIndex(el => el.classList.contains('demo--current'));
    const navigate = (linkEl) => {
        document.body.classList.remove('render');
        document.body.addEventListener('transitionend', () => window.location = linkEl.href);
    };
    navdemos.forEach(link => link.addEventListener('click', (ev) => {
        ev.preventDefault();
        navigate(ev.target);
    }));
    document.addEventListener('keydown', (ev) => {
        const keyCode = ev.keyCode || ev.which;
        let linkEl;
        if (keyCode === 37) {
            linkEl = current > 0 ? navdemos[current - 1] : navdemos[total - 1];
        }
        else if (keyCode === 39) {
            linkEl = current < total - 1 ? navdemos[current + 1] : navdemos[0];
        }
        else {
            return false;
        }
        navigate(linkEl);
    });
}

{
    class Slide {
        constructor(el) {
            this.DOM = { el: el };
            this.DOM.slideImg = this.DOM.el.querySelector('.slide__img');
            this.bgImage = this.DOM.slideImg.style.backgroundImage;
            this.layout();
        }
        layout() {
            this.DOM.slideImg.innerHTML = `<div class='glitch__img' style='background-image: ${this.DOM.slideImg.style.backgroundImage};'></div>`.repeat(5);
            this.DOM.glitchImgs = Array.from(this.DOM.slideImg.querySelectorAll('.glitch__img'));
            this.DOM.slideImg.style.backgroundImage = 'none';
        }
        changeBGImage(bgimage, pos = 0, delay = 0) {
            setTimeout(() => this.DOM.glitchImgs[pos].style.backgroundImage = bgimage, delay);
        }
    }

    class GlitchSlideshow {
        constructor(el) {
            this.DOM = { el: el };
            this.DOM.slides = Array.from(this.DOM.el.querySelectorAll('.slide'));
            this.slidesTotal = this.DOM.slides.length;
            this.slides = [];
            this.DOM.slides.forEach(slide => this.slides.push(new Slide(slide)));
            this.current = 0;
            this.glitchTime = 900;
            this.totalGlitchSlices = 5;

            this.slideNav = document.querySelector('.slide-nav');
            for (let i = 0; i < this.slidesTotal; i++) {
                let span = document.createElement('span');
                span.setAttribute('class', 'glide__bullet');
                if (i == 0) {
                    span.setAttribute('class', 'glide__bullet glide__bullet--active');
                }
                span.innerText = ('00' + i).slice(-2);
                span.addEventListener('click', (e) => {
                    let spans = e.currentTarget.parentNode.querySelectorAll('span');
                    for (var j = 0; j < spans.length; j++) {
                        spans[j].classList.remove('glide__bullet--active');
                    }
                    this.navigate(i);
                    spans[i].classList.add('glide__bullet--active');
                });
                this.slideNav.appendChild(span);
            }
        }
        glitch(slideFrom, slideTo) {
            return new Promise((resolve, reject) => {
                slideFrom.DOM.slideImg.classList.add('glitch--animate');

                const slideFromBGImage = slideFrom.bgImage;
                const slideToBGImage = slideTo.bgImage;

                for (let i = this.totalGlitchSlices - 1; i >= 0; --i) {
                    slideFrom.changeBGImage(slideToBGImage, i, this.glitchTime / (this.totalGlitchSlices + 1) * (this.totalGlitchSlices - i - 1) + this.glitchTime / (this.totalGlitchSlices + 1));
                }

                setTimeout(() => {
                    slideFrom.DOM.slideImg.classList.remove('glitch--animate');

                    // reset bgimages.
                    for (let i = this.totalGlitchSlices - 1; i >= 0; --i) {
                        slideFrom.changeBGImage(slideFromBGImage, i, 0);
                    }

                    resolve();
                }, this.glitchTime);
            });
        }

        navigate(newCurrent) {
            if (this.isAnimating) return;
            this.isAnimating = true;
            this.glitch(this.slides[this.current], this.slides[newCurrent]).then(() => {
                this.DOM.slides[this.current].classList.remove('slide--current');
                this.current = newCurrent;
                this.DOM.slides[this.current].classList.add('slide--current');
                this.isAnimating = false;
            });
        }
        // navigate(dir) {
        //     if (this.isAnimating) return;
        //     this.isAnimating = true;

        //     const newCurrent = dir === 'next' ?
        //         this.current < this.slidesTotal - 1 ? this.current + 1 : 0 :
        //         this.current > 0 ? this.current - 1 : this.slidesTotal - 1;

        //     this.glitch(this.slides[this.current], this.slides[newCurrent]).then(() => {
        //         this.DOM.slides[this.current].classList.remove('slide--current');
        //         this.current = newCurrent;
        //         this.DOM.slides[this.current].classList.add('slide--current');
        //         this.isAnimating = false;
        //     });
        // }
    }
    if ($('.slides').length > 0) {
        const slideshow = new GlitchSlideshow(document.querySelector('.slides'));
    }
}
